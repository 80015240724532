// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@charset \"UTF-8\"\n/**\n * @description 全局主题变量配置\n */", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu-color": "hsla(0, 0%, 100%, 0.95)",
	"menu-color-active": "hsla(0, 0%, 100%, 0.95)",
	"menu-background": "#282c34",
	"column-second-menu-background": "#fff",
	"vab-border-radius": "2.5px",
	"vab-color-grey": "rgba(0, 0, 0, 0.65)",
	"vab-color-black": "#515a6e",
	"vab-color-primary": "#1890ff",
	"vab-color-primary-light-1": "rgb(47.1, 155.1, 255)",
	"vab-color-primary-light-2": "rgb(70.2, 166.2, 255)",
	"vab-color-primary-light-3": "rgb(93.3, 177.3, 255)",
	"vab-color-primary-light-4": "rgb(116.4, 188.4, 255)",
	"vab-color-primary-light-5": "rgb(139.5, 199.5, 255)",
	"vab-color-primary-light-6": "rgb(162.6, 210.6, 255)",
	"vab-color-primary-light-7": "rgb(185.7, 221.7, 255)",
	"vab-color-primary-light-8": "rgb(208.8, 232.8, 255)",
	"vab-color-primary-light-9": "rgb(231.9, 243.9, 255)",
	"vab-color-success": "#13ce66",
	"vab-color-success-light": "rgb(207.8, 245.2, 224.4)",
	"vab-color-success-lighter": "rgb(231.4, 250.1, 239.7)",
	"vab-color-warning": "#ffba00",
	"vab-color-warning-light": "rgb(255, 241.2, 204)",
	"vab-color-warning-lighter": "rgb(255, 248.1, 229.5)",
	"vab-color-danger": "#ff4d4f",
	"vab-color-danger-light": "rgb(255, 219.4, 219.8)",
	"vab-color-danger-lighter": "rgb(255, 237.2, 237.4)",
	"vab-color-error": "#ff4d4f",
	"vab-color-error-light": "rgb(255, 219.4, 219.8)",
	"vab-color-error-lighter": "rgb(255, 237.2, 237.4)",
	"vab-color-info": "#909399",
	"vab-color-info-light": "rgb(232.8, 233.4, 234.6)",
	"vab-color-info-lighter": "rgb(243.9, 244.2, 244.8)",
	"vab-border-radius-base": "2.5px",
	"vab-color-transition": "#77e19d",
	"vab-left-menu-width": "266px"
};
export default ___CSS_LOADER_EXPORT___;
