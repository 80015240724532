import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.map.js";
import { __awaiter, __generator, __read, __spreadArray } from "tslib";
/**
 * @description 路由拦截状态管理，目前两种模式：all模式与intelligence模式，其中partialRoutes是菜单暂未使用
 */
import { asyncRoutes, constantRoutes, resetRouter } from '@/router';
import { filterRoutes } from '@/utils/routes';
import { accessControl } from '@/config';
export var useRoutesStore = defineStore('routes', {
  state: function () {
    return {
      /**
       * 一级菜单值
       */
      tab: {
        data: undefined
      },
      /**
       * 一级菜单
       */
      tabMenu: undefined,
      /**
       * 自定义激活菜单
       */
      activeMenu: {
        data: undefined
      },
      /**
       * 一级菜单
       */
      routes: []
    };
  },
  getters: {
    getTab: function (state) {
      return state.tab;
    },
    getTabMenu: function (state) {
      return state.tab.data ? state.routes.find(function (route) {
        return route.name === state.tab.data;
      }) : {
        meta: {
          title: ''
        },
        redirect: '404'
      };
    },
    getActiveMenu: function (state) {
      return state.activeMenu;
    },
    getRoutes: function (state) {
      return state.routes.filter(function (_route) {
        return _route.meta.hidden !== true;
      });
    },
    getPartialRoutes: function (state) {
      var _a;
      return ((_a = state.routes.find(function (route) {
        return route.name === state.tab.data;
      })) === null || _a === void 0 ? void 0 : _a.children) || [];
    }
  },
  actions: {
    clearRoutes: function () {
      this.routes = [];
    },
    /**
     * @description 多模式设置路由
     * @param mode
     * @returns
     */
    setRoutes: function (mode) {
      if (mode === void 0) {
        mode = 'none';
      }
      return __awaiter(this, void 0, void 0, function () {
        var routes, control, accessRoutes;
        return __generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              routes = __spreadArray([], __read(asyncRoutes), false);
              control = mode === 'visit' ? false : accessControl;
              accessRoutes = filterRoutes(__spreadArray(__spreadArray([], __read(constantRoutes), false), __read(routes), false), control);
              // 设置菜单所需路由
              this.routes = JSON.parse(JSON.stringify(accessRoutes));
              // 根据可访问路由重置Vue Router
              return [4 /*yield*/, resetRouter(accessRoutes)];
            case 1:
              // 根据可访问路由重置Vue Router
              _a.sent();
              return [2 /*return*/];
          }
        });
      });
    },
    changeMenuMeta: function (options) {
      function handleRoutes(routes) {
        return routes.map(function (route) {
          if (route.name === options.name) Object.assign(route.meta, options.meta);
          if (route.children && route.children.length) route.children = handleRoutes(route.children);
          return route;
        });
      }
      this.routes = handleRoutes(this.routes);
    },
    /**
     * @description 修改 activeName
     * @param activeMenu 当前激活菜单
     */
    changeActiveMenu: function (activeMenu) {
      this.activeMenu.data = activeMenu;
    }
  }
});