// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@charset \"UTF-8\"\n/**\n * @description 全局主题变量配置\n */", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu-color": "hsla(0, 0%, 100%, 0.95)",
	"menu-color-active": "hsla(0, 0%, 100%, 0.95)",
	"menu-background": "#282c34",
	"column-second-menu-background": "#fff",
	"vab-border-radius": "2.5px",
	"vab-color-grey": "rgba(0, 0, 0, 0.65)",
	"vab-color-black": "#515a6e",
	"vab-color-primary": "#41b584",
	"vab-color-primary-light-1": "rgb(84, 188.4, 144.3)",
	"vab-color-primary-light-2": "rgb(103, 195.8, 156.6)",
	"vab-color-primary-light-3": "rgb(122, 203.2, 168.9)",
	"vab-color-primary-light-4": "rgb(141, 210.6, 181.2)",
	"vab-color-primary-light-5": "rgb(160, 218, 193.5)",
	"vab-color-primary-light-6": "rgb(179, 225.4, 205.8)",
	"vab-color-primary-light-7": "rgb(198, 232.8, 218.1)",
	"vab-color-primary-light-8": "rgb(217, 240.2, 230.4)",
	"vab-color-primary-light-9": "rgb(236, 247.6, 242.7)",
	"vab-color-success": "#13ce66",
	"vab-color-success-light": "rgb(207.8, 245.2, 224.4)",
	"vab-color-success-lighter": "rgb(231.4, 250.1, 239.7)",
	"vab-color-warning": "#ffba00",
	"vab-color-warning-light": "rgb(255, 241.2, 204)",
	"vab-color-warning-lighter": "rgb(255, 248.1, 229.5)",
	"vab-color-danger": "#ff4d4f",
	"vab-color-danger-light": "rgb(255, 219.4, 219.8)",
	"vab-color-danger-lighter": "rgb(255, 237.2, 237.4)",
	"vab-color-error": "#ff4d4f",
	"vab-color-error-light": "rgb(255, 219.4, 219.8)",
	"vab-color-error-lighter": "rgb(255, 237.2, 237.4)",
	"vab-color-info": "#909399",
	"vab-color-info-light": "rgb(232.8, 233.4, 234.6)",
	"vab-color-info-lighter": "rgb(243.9, 244.2, 244.8)",
	"vab-border-radius-base": "2.5px",
	"vab-color-transition": "#1890ff",
	"vab-left-menu-width": "266px"
};
export default ___CSS_LOADER_EXPORT___;
