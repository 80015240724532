import { useRoute } from 'vue-router';
import { inject, ref, reactive, computed, watchEffect, nextTick, onUnmounted } from 'vue';
import { storeToRefs } from 'pinia';
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.flat-map.js";
import { defineComponent as _defineComponent } from 'vue';
import { useSettingsStore } from '@/store/modules/settings';
import { useTabsStore } from '@/store/modules/tabs';
import { handleActivePath } from '@/utils/routes';
import { keepAliveMaxNum } from '@/config';
import { useHead } from '@vueuse/head';
import VabProgress from 'nprogress';
export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup: function (__props, _a) {
    var __expose = _a.expose;
    __expose();
    var route = useRoute();
    var $sub = inject('$sub');
    var $unsub = inject('$unsub');
    var settingsStore = useSettingsStore();
    var theme = storeToRefs(settingsStore).theme;
    var tabsStore = useTabsStore();
    var visitedRoutes = storeToRefs(tabsStore).getVisitedRoutes;
    var componentRef = ref();
    var routerKey = ref();
    var keepAliveNameList = ref();
    var siteData = reactive({
      description: ''
    });
    useHead({
      meta: [{
        name: "description",
        content: computed(function () {
          return siteData.description;
        })
      }]
    });
    var updateKeepAliveNameList = function (refreshRouteName) {
      if (refreshRouteName === void 0) {
        refreshRouteName = null;
      }
      keepAliveNameList.value = visitedRoutes.value.filter(function (item) {
        return !item.meta.noKeepAlive && item.name !== refreshRouteName;
      }).flatMap(function (item) {
        return item.name;
      });
    };
    // 更新KeepAlive缓存页面
    watchEffect(function () {
      routerKey.value = handleActivePath(route, true);
      updateKeepAliveNameList();
      siteData.description = "".concat('Vue', " ").concat('Admin', " ").concat('Plus', "-").concat(route.meta.title, "\u7B80\u4ECB\u3001\u5B98\u7F51\u3001\u9996\u9875\u3001\u6587\u6863\u548C\u4E0B\u8F7D - \u524D\u7AEF\u5F00\u53D1\u6846\u67B6");
    });
    $sub('get-code', function () {
      window.open("https://github.com/vue-admin-beautiful/admin-plus/blob/main/".concat(componentRef.value.$options.__source));
    });
    $sub('reload-router-view', function (refreshRouteName) {
      if (refreshRouteName === void 0) {
        refreshRouteName = route.name;
      }
      if (theme.value.showProgressBar) VabProgress.start();
      var cacheActivePath = routerKey.value;
      routerKey.value = null;
      updateKeepAliveNameList(refreshRouteName);
      nextTick(function () {
        routerKey.value = cacheActivePath;
        updateKeepAliveNameList();
      });
      setTimeout(function () {
        if (theme.value.showProgressBar) VabProgress.done();
      }, 200);
    });
    onUnmounted(function () {
      $unsub('get-code');
      $unsub('reload-router-view');
    });
    var __returned__ = {
      route: route,
      $sub: $sub,
      $unsub: $unsub,
      settingsStore: settingsStore,
      theme: theme,
      tabsStore: tabsStore,
      visitedRoutes: visitedRoutes,
      componentRef: componentRef,
      routerKey: routerKey,
      keepAliveNameList: keepAliveNameList,
      siteData: siteData,
      updateKeepAliveNameList: updateKeepAliveNameList,
      get keepAliveMaxNum() {
        return keepAliveMaxNum;
      }
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
});