import { inject, ref, watch, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useMouse } from '@vueuse/core';
import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { __awaiter, __generator } from "tslib";
import { defineComponent as _defineComponent } from 'vue';
import { useTabsStore } from '@/store/modules/tabs';
import { useRoutesStore } from '@/store/modules/routes';
import { useSettingsStore } from '@/store/modules/settings';
import { handleActivePath, handleTabs } from '@/utils/routes';
export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    layout: {
      type: String,
      default: ''
    }
  },
  setup: function (__props, _a) {
    var _this = this;
    var __expose = _a.expose;
    __expose();
    var $pub = inject('$pub');
    var route = useRoute();
    var router = useRouter();
    var settingsStore = useSettingsStore();
    var theme = storeToRefs(settingsStore).theme;
    var routesStore = useRoutesStore();
    var routes = storeToRefs(routesStore).getRoutes;
    var tabsStore = useTabsStore();
    var visitedRoutes = storeToRefs(tabsStore).getVisitedRoutes;
    var addVisitedRoute = tabsStore.addVisitedRoute,
      delVisitedRoute = tabsStore.delVisitedRoute,
      delOthersVisitedRoutes = tabsStore.delOthersVisitedRoutes,
      delLeftVisitedRoutes = tabsStore.delLeftVisitedRoutes,
      delRightVisitedRoutes = tabsStore.delRightVisitedRoutes,
      delAllVisitedRoutes = tabsStore.delAllVisitedRoutes;
    var tabActive = ref('');
    var active = ref(false);
    var hoverRoute = ref();
    var visible = ref(false);
    var top = ref(0);
    var left = ref(0);
    var isActive = function (path) {
      return path === handleActivePath(route, true);
    };
    var isNoCLosable = function (tag) {
      return tag.meta.noClosable;
    };
    var handleTabClick = function (tab) {
      if (!isActive(tab.name)) router.push(visitedRoutes.value[tab.index]);
    };
    var handleVisibleChange = function (val) {
      active.value = val;
    };
    var initNoCLosableTabs = function (routes) {
      routes.forEach(function (_route) {
        if (_route.meta.noClosable) addTabs(_route);
        if (_route.children) initNoCLosableTabs(_route.children);
      });
    };
    /**
     * 添加标签页
     * @param tag route
     * @returns {Promise<void>}
     */
    var addTabs = function (tag) {
      return __awaiter(_this, void 0, void 0, function () {
        var tab;
        return __generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              tab = handleTabs(tag);
              if (!tab) return [3 /*break*/, 2];
              return [4 /*yield*/, addVisitedRoute(tab)];
            case 1:
              _a.sent();
              tabActive.value = tab.path;
              _a.label = 2;
            case 2:
              return [2 /*return*/];
          }
        });
      });
    };
    /**
     * 根据原生路径删除标签中的标签
     * @param rawPath 原生路径
     * @returns {Promise<void>}
     */
    var handleTabRemove = function (rawPath) {
      return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              if (!isActive(rawPath)) return [3 /*break*/, 2];
              return [4 /*yield*/, toLastTab()];
            case 1:
              _a.sent();
              _a.label = 2;
            case 2:
              return [4 /*yield*/, delVisitedRoute(rawPath)];
            case 3:
              _a.sent();
              return [2 /*return*/];
          }
        });
      });
    };
    var handleCommand = function (command) {
      switch (command) {
        case 'refreshThisTab':
          refreshThisTab();
          break;
        case 'closeOthersTabs':
          closeOthersTabs();
          break;
        case 'closeLeftTabs':
          closeLeftTabs();
          break;
        case 'closeRightTabs':
          closeRightTabs();
          break;
        case 'closeAllTabs':
          closeAllTabs();
          break;
      }
    };
    /**
     * 刷新当前标签页
     * @returns {Promise<void>}
     */
    var refreshThisTab = function () {
      $pub('reload-router-view');
    };
    /**
     * 删除其他标签页
     * @returns {Promise<void>}
     */
    var closeOthersTabs = function () {
      return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              if (!hoverRoute.value) return [3 /*break*/, 3];
              return [4 /*yield*/, router.push(hoverRoute.value)];
            case 1:
              _a.sent();
              return [4 /*yield*/, delOthersVisitedRoutes(hoverRoute.value.path)];
            case 2:
              _a.sent();
              return [3 /*break*/, 5];
            case 3:
              return [4 /*yield*/, delOthersVisitedRoutes(handleActivePath(route, true))];
            case 4:
              _a.sent();
              _a.label = 5;
            case 5:
              return [4 /*yield*/, closeMenu()];
            case 6:
              _a.sent();
              return [2 /*return*/];
          }
        });
      });
    };
    /**
     * 删除左侧标签页
     * @returns {Promise<void>}
     */
    var closeLeftTabs = function () {
      return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              if (!hoverRoute.value) return [3 /*break*/, 3];
              return [4 /*yield*/, router.push(hoverRoute.value)];
            case 1:
              _a.sent();
              return [4 /*yield*/, delLeftVisitedRoutes(hoverRoute.value.path)];
            case 2:
              _a.sent();
              return [3 /*break*/, 5];
            case 3:
              return [4 /*yield*/, delLeftVisitedRoutes(handleActivePath(route, true))];
            case 4:
              _a.sent();
              _a.label = 5;
            case 5:
              return [4 /*yield*/, closeMenu()];
            case 6:
              _a.sent();
              return [2 /*return*/];
          }
        });
      });
    };
    /**
     * 删除右侧标签页
     * @returns {Promise<void>}
     */
    var closeRightTabs = function () {
      return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              if (!hoverRoute.value) return [3 /*break*/, 3];
              return [4 /*yield*/, router.push(hoverRoute.value)];
            case 1:
              _a.sent();
              return [4 /*yield*/, delRightVisitedRoutes(hoverRoute.value.path)];
            case 2:
              _a.sent();
              return [3 /*break*/, 5];
            case 3:
              return [4 /*yield*/, delRightVisitedRoutes(handleActivePath(route, true))];
            case 4:
              _a.sent();
              _a.label = 5;
            case 5:
              return [4 /*yield*/, closeMenu()];
            case 6:
              _a.sent();
              return [2 /*return*/];
          }
        });
      });
    };
    /**
     * 删除所有标签页
     * @returns {Promise<void>}
     */
    var closeAllTabs = function () {
      return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              return [4 /*yield*/, delAllVisitedRoutes()];
            case 1:
              _a.sent();
              return [4 /*yield*/, toLastTab()];
            case 2:
              _a.sent();
              return [4 /*yield*/, closeMenu()];
            case 3:
              _a.sent();
              return [2 /*return*/];
          }
        });
      });
    };
    /**
     * 跳转最后一个标签页
     */
    var toLastTab = function () {
      return __awaiter(_this, void 0, void 0, function () {
        var latestView;
        return __generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              latestView = visitedRoutes.value.filter(function (_) {
                return _.path !== handleActivePath(route, true);
              }).slice(-1)[0];
              if (!latestView) return [3 /*break*/, 2];
              return [4 /*yield*/, router.push(latestView)];
            case 1:
              _a.sent();
              return [3 /*break*/, 4];
            case 2:
              return [4 /*yield*/, router.push('/')];
            case 3:
              _a.sent();
              _a.label = 4;
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
    var _b = useMouse(),
      x = _b.x,
      y = _b.y;
    var openMenu = function () {
      left.value = x.value;
      top.value = y.value;
      visible.value = true;
    };
    var closeMenu = function () {
      visible.value = false;
      hoverRoute.value = null;
    };
    initNoCLosableTabs(routes.value);
    watch(function () {
      return route.fullPath;
    }, function () {
      addTabs(route);
    }, {
      immediate: true
    });
    watchEffect(function () {
      if (visible.value) document.body.addEventListener('click', closeMenu);else document.body.removeEventListener('click', closeMenu);
    });
    var __returned__ = {
      $pub: $pub,
      route: route,
      router: router,
      settingsStore: settingsStore,
      theme: theme,
      routesStore: routesStore,
      routes: routes,
      tabsStore: tabsStore,
      visitedRoutes: visitedRoutes,
      addVisitedRoute: addVisitedRoute,
      delVisitedRoute: delVisitedRoute,
      delOthersVisitedRoutes: delOthersVisitedRoutes,
      delLeftVisitedRoutes: delLeftVisitedRoutes,
      delRightVisitedRoutes: delRightVisitedRoutes,
      delAllVisitedRoutes: delAllVisitedRoutes,
      tabActive: tabActive,
      active: active,
      hoverRoute: hoverRoute,
      visible: visible,
      top: top,
      left: left,
      isActive: isActive,
      isNoCLosable: isNoCLosable,
      handleTabClick: handleTabClick,
      handleVisibleChange: handleVisibleChange,
      initNoCLosableTabs: initNoCLosableTabs,
      addTabs: addTabs,
      handleTabRemove: handleTabRemove,
      handleCommand: handleCommand,
      refreshThisTab: refreshThisTab,
      closeOthersTabs: closeOthersTabs,
      closeLeftTabs: closeLeftTabs,
      closeRightTabs: closeRightTabs,
      closeAllTabs: closeAllTabs,
      toLastTab: toLastTab,
      x: x,
      y: y,
      openMenu: openMenu,
      closeMenu: closeMenu
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
});